import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from '../atoms/Image';

const Holder = styled.div`

`;

const ImageHolder = styled.div`
  margin-bottom: 2rem;
  .gatsby-image-wrapper { height: 15rem; }
`;

function PageHero( { image, title } ) {
  return (
    <Holder>
      <h1>{title}</h1>
      <ImageHolder>
        {image && typeof image === 'string' && <Image imgName={image}/>}
        {image && typeof image !== 'string' && image}
      </ImageHolder>
    </Holder>
  )
}

PageHero.propTypes = {
  image: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.element
  ] ),
  title: PropTypes.string.isRequired,
};

PageHero.defaultProps = {
  propName: 'Stranger'
};

export default PageHero;
