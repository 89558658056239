import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import PageHero from '../components/organisms/PageHero';
import { graphql } from 'gatsby';
import Image from '../components/atoms/Image';

const Holder = styled.div`
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: repeat(3, 1fr);
  }
`;

function DrawingsPage( { data } ) {

  return (
    <>
      <Seo title="Drawings" description="The Unicorns Forever stream of consciousness"/>
      <Holder>
        <Images>
          {data.allFile.nodes.map( node =>
            <Image key={node.id} imgName={node.childImageSharp.fluid.originalName}/>
          )}
        </Images>
      </Holder>
    </>
  )
}

export default DrawingsPage;

export const drawingsPageQuery = graphql`
    query DrawingsQuery {
        allFile(
            filter: {absolutePath: {regex : "/images/drawings/"}}
            sort: {fields: birthtime, order: DESC}
        ) {
            nodes {
                absolutePath
                id
                childImageSharp {
                    fluid {
                        originalName
                    }
                }
            }
        }
    }
`;

